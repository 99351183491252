<template>
  <span
    class="tag"
    :class="`tag--${theme}`"
  >
    {{ text }}
  </span>
</template>

<script lang="ts">
import { TagTheme } from '~/types/tagTheme'

export default {
  name: 'ReflectTag',
  props: {
    text: {
      type: String,
      default: ''
    },
    theme: {
      type: String as PropType<TagTheme>,
      default: TagTheme.PRIMARY
    }
  }
}
</script>

<style lang="scss" scoped>
.tag {
  display: flex;
  align-items: center;
  @include font-text;
  @include font-size($font-size-mini, 22px);
  background-color: $tag-primary;
  border-radius: 4px;
  padding: 0 8px;

  &--primary {
    background-color: $tag-primary;
  }

  &--secondary {
    background-color: $tag-secondary;
  }

  &--tertiary {
    background-color: $tag-tertiary;
  }

  &--quaternary {
    background-color: $tag-quaternary;
  }

  &--quinary {
    background-color: $tag-quinary;
  }

  &--octonary {
    background-color: $tag-octonary;
    color: $text-inverse;
  }

  &--greyed {
    background-color: $tag-greyed;
  }
}
</style>
